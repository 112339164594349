/**
 * User constants
 */
export const STORE_USER = '[User] STORE_USER';
export const FETCH_USER_POLICY_START = '[User] FETCH_USER_POLICY_START';
export const FETCH_USER_POLICY = '[User] FETCH_USER_POLICY';
export const SET_USER_POLICY = '[User] SET_USER_POLICY';
export const FETCH_USER_POLICY_FAIL = '[User] FETCH_USER_POLICY_FAIL';
export const UPDATE_TERMS_AND_CONDITIONS = '[User] UPDATE_TERMS_AND_CONDITIONS';
export const UPDATE_TERMS_AND_CONDITIONS_FAIL = '[User] UPDATE_TERMS_AND_CONDITIONS_FAIL';
export const UPDATE_USER_PROFILE_OKTA_FAIL = '[User] UPDATE_USER_PROFILE_OKTA_FAIL';
export const UPDATE_USER_PROFILE_OKTA = '[User] UPDATE_USER_PROFILE_OKTA';
export const UPDATE_USER_PASSWORD_OKTA = '[User] UPDATE_USER_PASSWORD_OKTA';
export const CLEAN_OKTA_MESSAGES_PASSWORD = '[User] CLEAN_OKTA_MESSAGES_PASSWORD';

export const SET_USER_ROLE_CONFIG = '[User] SET_USER_ROLE_CONFIG';
export const SET_USER_ROLE_CONFIG_FAIL = '[User] SET_USER_ROLE_CONFIG_FAIL';
export const SET_USER_APP_CONFIG = '[User] SET_USER_APP_CONFIG';
export const SET_USER_PERMISSIONS = '[User] SET_USER_PERMISSIONS';
