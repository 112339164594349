/* eslint-disable global-require */
const images = {
  powerBI: require('./images/icons/menu/PowerBI.svg').default,
  logoWhiteSM: require('./images/logo-white-sm.svg').default,
  logoBrandingSM: require('./images/logo-eec-white-sm@3x.png'),
  loginBackImage: require('./images/entech-lifestyle-square.png'),
  loginBrandingImage: require('./images/logo-branding-lg.jpg').default,
  iconBellWhite: require('./images/icons/appBar/ic-bell.svg').default,
  iconLogout: require('./images/icons/appBar/ic-logout.svg').default,
  emptyEnvelop: require('./images/icons/appBar/ic-notification.svg').default,
  iconDashboard: require('./images/icons/menu/Dashboard.svg').default,
  iconSettings: require('./images/icons/menu/menu-configuration.svg').default,
  iconSites: require('./images/icons/menu/menu-sites.svg').default,
  iconConfiguration: require('./images/icons/large/ic-config.svg').default,
  iconUtility: require('./images/icons/categories/ic-utility.svg').default,
  iconSolar: require('./images/icons/categories/ic-solar.svg').default,
  iconEss: require('./images/icons/categories/ic-ess.svg').default,
  iconDdr: require('./images/icons/categories/ic-ddr.svg').default,
  iconEv: require('./images/icons/categories/ic-ev.svg').default,
  iconBattery: require('./images/icons/categories/ic-battery.svg').default,
  iconCarBattery: require('./images/icons/categories/ic-car-battery.svg').default,
  iconWind: require('./images/icons/categories/ic-wind.svg').default,
  iconFleet: require('./images/icons/categories/ic-fleet.svg').default,
  iconFuel: require('./images/icons/categories/ic-fuel.svg').default,
  iconInverter: require('./images/icons/categories/ic-inverter.svg').default,
  iconAll: require('./images/icons/categories/ic-all.svg').default,
  searchIcon: require('./images/icons/small/ic-search.svg').default,
  notificationRowBellIcon: require('./images/icons/small/group.svg').default,
  alertIcon: require('./images/icons/small/ic-alert.svg').default,
  closeIcon: require('./images/icons/small/ic-close.svg').default,
  closeIconGreen: require('./images/icons/small/ic-close-green.svg').default,
  maintenanceBroadcastIcon: require('./images/icons/small/ic-maintenance.svg').default,
  alertIconLarge: require('./images/icons/small/ic-alert@2x.png'),
  editGreenIcon: require('./images/icons/small/ic-edit-green.svg').default,
  editDarkIcon: require('./images/icons/small/ic-edit-dark.svg').default,
  deleteIcon: require('./images/icons/small/ic-delete.svg').default,
  chevronIcon: require('./images/icons/small/ic-chevron-down.svg').default,
  chevronGreenIcon: require('./images/icons/small/ic-chevron-down-green.svg').default,
  chevronGreyIcon: require('./images/icons/small/ic-chevron-down-grey.svg').default,
  chevronOrangeIcon: require('./images/icons/small/ic-chevron-down-orange.svg').default,
  chevronPurpleIcon: require('./images/icons/small/ic-chevron-down-purple.svg').default,
  chevronRedIcon: require('./images/icons/small/ic-chevron-down-red.svg').default,
  chevronYellowIcon: require('./images/icons/small/ic-chevron-down-yellow.svg').default,
  checkIcon: require('./images/icons/small/ic-check.svg').default,
  elipsisIcon: require('./images/icons/small/elipsis.svg').default,
  bellIcon: require('./images/icons/small/ic-bell.svg').default,
  redBellIcon: require('./images/icons/small/ic-bell-red.svg').default,
  deleteDarkIcon: require('./images/icons/small/ic-delete-dark.svg').default,
  duplicateIcon: require('./images/icons/small/ic-duplicate.svg').default,
  assignIcon: require('./images/icons/small/assign.svg').default,
  pinAlarm: require('./images/icons/status/ic-pin-alarm.svg').default,
  pinFaulted: require('./images/icons/status/ic-pin-faulted.svg').default,
  pinMaintenance: require('./images/icons/status/ic-pin-maintenance.svg').default,
  pinOk: require('./images/icons/status/ic-pin-ok.svg').default,
  pinWarning: require('./images/icons/status/ic-pin-warning.svg').default,
  pinWhite: require('./images/icons/status/ic-pin-white.svg').default,
  pin: require('./images/pin.svg').default,
  map: require('./images/icons/large/ic-map.svg').default,
  mapLocate: require('./images/icons/small/ic-map-locate.svg').default,
  mapExpand: require('./images/icons/small/ic-map-expand.svg').default,
  iconOk: require('./images/btn-checked.svg').default,
  iconWifiConnected: require('./images/icons/connection/ic-wifi-connected.svg').default,
  iconWifiDisconnected: require('./images/icons/connection/ic-wifi-disconnected.svg').default,
  iconCellular: require('./images/icons/connection/ic-cellular.svg').default,
  iconUnknown: require('./images/icons/connection/ic-unknown.svg').default,
  iconWifiConnectedWhite: require('./images/icons/connection/ic-wifi-connected-white.svg').default,
  iconWifiDisconnectedWhite: require('./images/icons/connection/ic-wifi-disconnected-white.svg')
    .default,
  iconCellularWhite: require('./images/icons/connection/ic-cellular-white.svg').default,
  iconUnknownWhite: require('./images/icons/connection/ic-unknown-white.svg').default,
  iconMaintenance: require('./images/icons/status/ic-maintenance.svg').default,
  iconFaulted: require('./images/icons/status/ic-faulted.svg').default,
  iconAlarm: require('./images/icons/status/ic-alarm.svg').default,
  iconWarning: require('./images/icons/status/ic-warning.svg').default,
  iconCheck: require('./images/icons/status/ic-check.svg').default,
  iconTower: require('./images/icons/connection/ic-tower.svg').default,
  iconIsland: require('./images/icons/connection/ic-island.svg').default,
  reports: require('./images/icons/menu/menu-reports.svg').default,
  reportEmail: require('./images/01-icons-01-small-ic-share.png'),
  reportPrint: require('./images/01-icons-01-small-ic-print.png'),
  reportPdf: require('./images/01-icons-01-small-ic-pdf.svg').default,
  viewList: require('./images/icons/small/ic-view-list.svg').default,
  viewMap: require('./images/icons/small/ic-view-map.svg').default,
  iconInfo: require('./images/icons/small/ic-info.svg').default,
  microsoftIcon: require('./images/ic-logowindows.png'),
  dCentrIQPowerCentral: require('./images/02-images-img-logo-decentriq.svg').default,
  xCapePowerCentral: require('./images/02-images-img-logo-xcape.svg').default,
  downloadExcel: require('./images/01-icons-01-small-ic-excel.png'),
  eyeVisibility: require('./images/icons/small/eye-visibility.svg').default,
  eyeVisibilityOff: require('./images/icons/small/eye-visibilityoff.svg').default,
  iconEmulator: require('./images/icons/status/ic-emulator.svg').default,
  iconLocalIO: require('./images/icons/categories/ic-localio.svg').default,
  iconGid: require('./images/icons/categories/ic-gid.svg').default,
  iconAts: require('./images/icons/categories/ic-ats.svg').default,
  iconMeters: require('./images/icons/categories/ic-meters.svg').default,
  emulatedSites: require('./images/icons/small/emulated.svg').default,
  noEmulatedSites: require('./images/icons/small/no-emulated.svg').default,
  iconOverviewAndBackground:
    require('./images/icons/resources/01-icons-02-categories-ic-overview.svg').default,
  iconCustomerDocumentation:
    require('./images/icons/resources/01-icons-02-categories-ic-customer.svg').default,
  iconIcianDocumentation: require('./images/icons/resources/01-icons-02-categories-ic-ician.svg')
    .default,
  iconSupportTeamDocumentation:
    require('./images/icons/resources/01-icons-02-categories-ic-support.svg').default,
  iconResources: require('./images/icons/menu/Resources.svg').default,
  iconComissioning: require('./images/icons/status/ic-comissioning.png'),
};

export default images;
