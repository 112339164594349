/**
 *
 * AppWithRouterAccess.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */
import { toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import OktaLogin from 'containers/Launch/Login';
import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import TermsAndConditionsPage from 'containers/Launch/Login/Legal/TermsAndConditions/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import PowerBIPage from 'containers/PowerBIPage/Loadable';
import SitesDashboard from 'containers/SitesPage/Loadable';
import ReportingPage from 'containers/ReportingPage/Loadable';
import ResourcesPage from 'containers/ResourcesPage/Loadable';
import SettingsPage from 'containers/SettingsPage/Loadable';
import DeviceDetailsPage from 'containers/SitesPage/DeviceDetails/Loadable';
import SitesDetailsPage from 'containers/SitesPage/SiteDetails/Loadable';
import { oktaAuthClient, oktaSignInConfig } from 'system/auth';
import { initializeHttpInterceptors } from 'system/httpHelper';

/**
 * AppWithRouterAccess Method is executed to load all the components in place
 * and run the application.
 * It also includes the routes declaration.
 */
function AppWithRouterAccess() {
  const oktaAuth = oktaAuthClient;
  const history = useHistory();

  const customAuthHandler = () => {
    history.push('/login');
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '', window.location.origin));
  };

  initializeHttpInterceptors();

  /**
   * Displays terms and conditions page
   */

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Switch>
        <SecureRoute exact path="/" component={SitesDashboard} />
        <SecureRoute exact path="/reporting" component={ReportingPage} />
        <SecureRoute exact path="/dashboard" component={PowerBIPage} />
        <SecureRoute exact path="/settings" component={SettingsPage} />
        <SecureRoute exact path="/resources" component={ResourcesPage} />
        <SecureRoute
          exact
          history={history}
          path="/sites/:siteId/details/:tabName?"
          component={SitesDetailsPage}
        />
        <SecureRoute
          exact
          path="/sites/:siteId/group/:deviceGroupId/device/:deviceId/details"
          component={DeviceDetailsPage}
        />
        <SecureRoute exact path="/legal/terms-and-conditions" component={TermsAndConditionsPage} />
        <Route path="/login" render={() => <OktaLogin config={oktaSignInConfig} />} />
        <Route path="/login/callback" component={LoginCallback} />
        <SecureRoute path="*" component={NotFoundPage} />
      </Switch>
    </Security>
  );
}

export default AppWithRouterAccess;
